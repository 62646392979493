import React, { useState } from "react";
import Loader from "./Loader";
export default function TermCondition() {
  const [isLoad, setIsLoad] = useState(true);

  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <section className="p-6  text-gray-800 rounded-lg shadow-md m-4">
      <h1 className="text-3xl font-bold text-center mb-6">
        Terms and Conditions
      </h1>

      <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
      <p className="mb-4">
        Welcome to vanguardscholars.xyz. By accessing and using our website, you
        agree to comply with and be bound by the following terms and conditions.
        Please review them carefully before using our services.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Use of the Website</h2>
      <ul className="list-disc list-inside mb-4">
        <li className="mb-2">
          <strong>Eligibility:</strong> You must be at least 18 years old to use
          this website.
        </li>
        <li className="mb-2">
          <strong>Account Responsibility:</strong> You are responsible for
          maintaining the confidentiality of your account information and
          password.
        </li>
        <li className="mb-2">
          <strong>Prohibited Activities:</strong> You agree not to engage in any
          unlawful activities or use the website in a way that could harm
          vanguardscholars.xyz or other users.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">Intellectual Property</h2>
      <p className="mb-4">
        All content on this website, including text, graphics, logos, and
        software, is the property of vanguardscholars.xyz and is protected by
        intellectual property laws. You may not reproduce, distribute, or create
        derivative works from any content without our explicit permission.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Limitation of Liability</h2>
      <p className="mb-4">
        vanguardscholars.xyz is not liable for any damages arising from the use
        of our website or services. We provide the website "as is" without any
        warranties of any kind.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Changes to Terms</h2>
      <p className="mb-4">
        We reserve the right to modify these terms and conditions at any time.
        Changes will be effective immediately upon posting on the website. Your
        continued use of the website constitutes your acceptance of the new
        terms.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
      <p>
        If you have any questions about these terms, please contact us at{" "}
        <a
          href="mailto:support@vanguardscholars.xyz"
          className="text-blue-500 hover:underline"
        >
          support@vanguardscholars.xyz
        </a>
        .
      </p>
    </section>
  );
}
