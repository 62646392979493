import React, { useState } from "react";
import Loader from "./Loader";

export default function About() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);
  return isLoad ? (
    <Loader />
  ) : (
    <section className="p-6 text-gray-800 rounded-lg shadow-md m-4">
      <h1 className="text-3xl font-bold text-center mb-6">About Us</h1>

      <h2 className="text-2xl font-semibold mb-4">
        Welcome to Vanguard Scholars!
      </h2>
      <p className="mb-4">
        At Vanguard Scholars, we are dedicated to advancing education and
        fostering academic excellence. Our mission is to provide innovative
        resources and support to help scholars and students achieve their
        academic and professional goals.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Our Story</h2>
      <p className="mb-4">
        Founded in 2024, Vanguard Scholars was established with the vision of
        creating a platform that supports and empowers learners through
        high-quality educational resources and guidance. We aim to be at the
        forefront of educational advancement and support.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Our Team</h2>
      <p className="mb-4">
        Our team consists of experienced educators, researchers, and academic
        professionals who are passionate about promoting education and scholarly
        success. We work collaboratively to offer valuable resources and support
        tailored to the needs of our users.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Our Services</h2>
      <ul className="list-disc list-inside mb-4">
        <li className="mb-2">
          <strong>Academic Resources:</strong> Access a wide range of resources
          designed to support academic research and learning.
        </li>
        <li className="mb-2">
          <strong>Scholarship Opportunities:</strong> Discover scholarship
          programs and funding opportunities to support your educational
          pursuits.
        </li>
        <li className="mb-2">
          <strong>Research Support:</strong> Receive assistance and guidance on
          academic research and writing.
        </li>
      </ul>

      <p>
        Thank you for choosing Vanguard Scholars. We are excited to support your
        academic journey and contribute to your success.
      </p>
    </section>
  );
}
