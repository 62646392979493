import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Games from "../Games.json";
import Loader from "./Loader";

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

export default function Home() {
  const [isLoad, setIsLoad] = useState(true);
  const [randomGames, setRandomGames] = useState([]);

  useEffect(() => {
    const shuffledGames = shuffleArray([...Games]).slice(0, 20);
    setRandomGames(shuffledGames);
  }, []);

  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 w-full">
      {randomGames.map((data, index) => (
        <div
          className="relative rounded-lg overflow-hidden bg-white dark:bg-gray-800 transform transition-transform duration-300 hover:scale-95 hover:shadow-xl"
          key={index}
        >
          <div className="relative group">
            <img
              className="w-full h-64 object-cover transition-transform duration-500"
              src={data.logo}
              alt={data.title}
            />
            <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-transparent opacity-0 transition-opacity duration-400 group-hover:opacity-70"></div>
          </div>

          <Link to={`/single?slug=${data.slug}`} className="text-white text-xl">
            <div className="p-2 flex items-center bg-white dark:bg-gray-800">
              <div className="bg-gradient-to-r from-[#514b82] via-[#403b70] to-[#332f5c] text-[#fff] rounded-full p-2 flex items-center justify-center">
                <svg
                  className="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M14.752 11.168l-6.207-3.695A1 1 0 007 8.305v7.39a1 1 0 001.545.832l6.207-3.695a1 1 0 000-1.664z"
                  />
                </svg>
              </div>
              <span className="text-xl ms-3 font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#514b82] via-[#403b70] to-[#332f5c]">
                {data.title}
              </span>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
}
