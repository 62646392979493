import React from "react";

export default function Loader() {
  return (
    <section
      className="flex items-center justify-center p-8"
      style={{ height: "calc(100vh - 300px" }}
    >
      <div className="loader flex items-center justify-center"></div>
    </section>
  );
}
